(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('PractitionerDialogController', PractitionerDialogController);

    PractitionerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Practitioner', 'Treatment'];

    function PractitionerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Practitioner, Treatment) {
        var vm = this;

        vm.practitioner = entity;
        vm.clear = clear;
        vm.save = save;
        // Practitioner could have existing treatments from other practices, so we merge them with the treatments of the currently associated practices
        if (vm.practitioner.id != null) {
            Treatment.getByPracticesOfPractitioner({practitionerId: vm.practitioner.id}, function (treatmentsOfCurrentRelatedPractices) {
                var existingTreatments = vm.practitioner.treatments;
                vm.treatments = filterDuplicateElements(existingTreatments.concat(treatmentsOfCurrentRelatedPractices), function (e) { return e.id; });
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        
        function filterDuplicateElements(array, keyFunction) {
            var seen = {};
            return array.filter(function(item) {
                var k = keyFunction(item);
                return seen.hasOwnProperty(k) ? false : (seen[k] = true);
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            
            if (vm.practitioner.title === '') {
                vm.practitioner.title = null;
            }
            
            if (vm.practitioner.id !== null) {
                Practitioner.update(vm.practitioner, onSaveSuccess, onSaveError);
            } else {
                Practitioner.save(vm.practitioner, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('getwellApp:practitionerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
