(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('PractitionerController', PractitionerController);

    PractitionerController.$inject = ['$scope', '$state', 'Practitioner', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Treatment'];

    function PractitionerController ($scope, $state, Practitioner, ParseLinks, AlertService, paginationConstants, pagingParams, Treatment) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();

        function loadAll () {
            Practitioner.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.practitioners = data;
                vm.page = pagingParams.page;
                vm.practitioners.forEach(function(pract) {
                    if (pract.treatments && pract.treatments.length) {
                        var practiceNames = new Set();
                        var practiceIds = new Set();
                        pract.treatments.forEach(function(treat) { practiceNames.add(treat.practice.name); practiceIds.add(treat.practice.id); });
                        pract.practiceIds = "";
                        pract.practiceNames = "";
                        practiceNames.forEach(function(name) { pract.practiceNames += name + ","; });
                        practiceIds.forEach(function(id) { pract.practiceIds += id + ","; });
                    }
                });
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
