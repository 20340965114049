(function() {
    'use strict';

    angular
        .module('getwellApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('treatment', {
            parent: 'entity',
            url: '/treatment?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'getwellApp.treatment.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/treatment/treatments.html',
                    controller: 'TreatmentController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('treatment');
                    $translatePartialLoader.addPart('treatmentType');
                    $translatePartialLoader.addPart('currency');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('treatment-detail', {
            parent: 'treatment',
            url: '/treatment/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'getwellApp.treatment.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/treatment/treatment-detail.html',
                    controller: 'TreatmentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('treatment');
                    $translatePartialLoader.addPart('treatmentType');
                    $translatePartialLoader.addPart('currency');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Treatment', function($stateParams, Treatment) {
                    return Treatment.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'treatment',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('treatment.add-default-treatments', {
        	parent: 'treatment',
        	url: '/add-default-treatments',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/treatment/add-default-treatments-dialog.html',
        			controller: 'AddDefaultTreatmentsDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						fixedPrice: null,
        						minPrice: null,
        						maxPrice: null,
        						type: null,
        						duration: 30,
        						currency: 'CHF',
        						showPrice: false,
        						showDuration: false,
        						displayOnStandalone: true,
        						displayOnPlatform: true,
        						customName: null,
                                sortingOrder: null,
                                id: null
        					};
        				}
        			}
        		}).result.then(function() {
        			$state.go('treatment', null, { reload: 'treatment' });
        		}, function() {
        			$state.go('treatment');
        		});
        	}]
        })
        .state('treatment-detail.edit', {
            parent: 'treatment-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/treatment/treatment-dialog.html',
                    controller: 'TreatmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Treatment', function(Treatment) {
                            return Treatment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('treatment.new', {
            parent: 'treatment',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/treatment/treatment-dialog.html',
                    controller: 'TreatmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                fixedPrice: null,
                                minPrice: null,
                                maxPrice: null,
                                type: null,
                                duration: 30,
                                currency: 'CHF',
                                showPrice: false,
                                showDuration: false,
                                displayOnStandalone: true,
                                displayOnPlatform: true,
                                customName: null,
                                sortingOrder: null,
                                externalCalendarId: null,
                                externalCalendarData: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('treatment', null, { reload: 'treatment' });
                }, function() {
                    $state.go('treatment');
                });
            }]
        })
        .state('treatment.edit', {
            parent: 'treatment',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/treatment/treatment-dialog.html',
                    controller: 'TreatmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Treatment', function(Treatment) {
                            return Treatment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('treatment', null, { reload: 'treatment' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('treatment.delete', {
            parent: 'treatment',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/treatment/treatment-delete-dialog.html',
                    controller: 'TreatmentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Treatment', function(Treatment) {
                            return Treatment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('treatment', null, { reload: 'treatment' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
