(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('PracticeDialogController', PracticeDialogController);

    PracticeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'Practice', 'Calendar', 'Practitioner', 'PracticeProfile'];

    function PracticeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, Practice, Calendar, Practitioner, PracticeProfile) {
        var vm = this;

        vm.practice = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.calendars = Calendar.query();
//        vm.practitioners = Practitioner.query();
        vm.practitioners = Practitioner.query({size: 5000});
        
        vm.practitionersString = "";
        if (vm.practice.practitioners != null) {
            vm.practice.practitioners.forEach(function(p,i) {
            	var string;
            	if (i==0) {
            		string = String(p.id);
            	} else {
            		string = ", " + String(p.id);
            	}
            	vm.practitionersString = vm.practitionersString.concat(string);
            });
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
        	var practitionersTemp = [];
        	
            vm.isSaving = true;
            
            if(vm.practice.practiceProfile != null && vm.practice.practiceProfile.id == null) {
            	vm.practice.practiceProfile = null;
            }
            
            vm.practitonersString = vm.practitionersString.split(',').forEach(function (string, j) {
        		if(isNaN(string)) {
                	alert("Error found when parsing entered id in location "+(j+1)+"! Put only numbers (practitioner id's) between the commas!");
                	onSaveError();
        		} else {
        			for(var i = 0; i < vm.practitioners.length; i++) {
        			    if (vm.practitioners[i].id == parseInt(string)) {
        			    	practitionersTemp.push(vm.practitioners[i]);
        			    	break;
        			    } else if (i == vm.practitioners.length - 1) {
                        	alert("There is no practitioner with id: "+string+"! Saving without this one..");
        			    }
        			}
        		}
        	});
            
            if(vm.isSaving) {
            	vm.practice.practitioners = [];
            	for(var i = 0; i < practitionersTemp.length; i++) {
            		vm.practice.practitioners.push(practitionersTemp[i]);
            	}
            	
            	
            }
            
            if (vm.practice.id !== null) {
                Practice.update(vm.practice, onSaveSuccess, onSaveError);
            } else {
                Practice.save(vm.practice, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('getwellApp:practiceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setPicture = function ($file, practice) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        practice.picture = base64Data;
                        practice.pictureContentType = $file.type;
                    });
                });
            }
        };

    }
})();
