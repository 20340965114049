(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('BookingDialogController', BookingDialogController);

    BookingDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Booking', 'EndCustomer', 'Treatment', 'Coupon', 'Practitioner'];

    function BookingDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Booking, EndCustomer, Treatment, Coupon, Practitioner) {
        var vm = this;

        vm.booking = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        // TODO [dom] change implementation here such that only the id (and not the whole endcustomer or treatment object) is used
//        vm.endcustomers = EndCustomer.query();
//        vm.treatments = Treatment.query();
//        vm.practitioners = Practitioner.query();
        vm.coupons = Coupon.query({size: 100});
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            
            if (vm.booking.appointmentChange === '') {
            	vm.booking.appointmentChange = null;
            }
            
            if (vm.booking.id !== null) {
                Booking.update(vm.booking, onSaveSuccess, onSaveError);
            } else {
                Booking.save(vm.booking, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('getwellApp:bookingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.bookingTime = false;
        vm.datePickerOpenStatus.appointmentStartTime = false;
        vm.datePickerOpenStatus.appointmentEndTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
