(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('EndCustomerDeleteController',EndCustomerDeleteController);

    EndCustomerDeleteController.$inject = ['$uibModalInstance', 'entity', 'EndCustomer'];

    function EndCustomerDeleteController($uibModalInstance, entity, EndCustomer) {
        var vm = this;

        vm.endCustomer = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            EndCustomer.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
