(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('PracticeProfileDeleteController',PracticeProfileDeleteController);

    PracticeProfileDeleteController.$inject = ['$uibModalInstance', 'entity', 'PracticeProfile'];

    function PracticeProfileDeleteController($uibModalInstance, entity, PracticeProfile) {
        var vm = this;

        vm.practiceProfile = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PracticeProfile.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
