(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('TreatmentExperienceDetailController', TreatmentExperienceDetailController);

    TreatmentExperienceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TreatmentExperience', 'Booking', 'TreatmentExperienceResponse'];

    function TreatmentExperienceDetailController($scope, $rootScope, $stateParams, previousState, entity, TreatmentExperience, Booking, TreatmentExperienceResponse) {
        var vm = this;

        vm.treatmentExperience = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('getwellApp:treatmentExperienceUpdate', function(event, result) {
            vm.treatmentExperience = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
