(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('AddDefaultTreatmentsDialogController', AddDefaultTreatmentsDialogController);

    AddDefaultTreatmentsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Treatment', 'Practice', 'Coupon'];

    function AddDefaultTreatmentsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Treatment, Practice, Coupon) {
        var vm = this;

        vm.treatment = entity;
        vm.clear = clear;
        vm.save = save;
        
        vm.practices = Practice.query({size: 2000});
        vm.coupons = Coupon.query({size: 2000});
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {
        	console.log("evo me, tu sam");
        	System.out.println("evo me tu sam");
        }

        function onSaveSuccess (result) {
            $scope.$emit('getwellApp:treatmentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
