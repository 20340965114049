(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('PracticeProfileController', PracticeProfileController);

    PracticeProfileController.$inject = ['$scope', '$state', 'PracticeProfile', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Practice'];

    function PracticeProfileController ($scope, $state, PracticeProfile, ParseLinks, AlertService, paginationConstants, pagingParams, Practice) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        
        loadAll();

        function loadAll () {
            PracticeProfile.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.practiceProfiles = data;
                vm.page = pagingParams.page;
                
                loadPracticeNames();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function loadPracticeNames() {
            Practice.query({
                size: 10000 // FIXME [dom] can/will not be enough; should be a new, dedicated query only getting practice.name and practice.practiceProfile.id based on vm.practiceProfiles
            }, onSuccess, onError);
            function onSuccess(response, headers) {
                
                for(var j = 0; j<vm.practiceProfiles.length;j++) {
                    for(var i = 0; i<response.length;i++) {
                        if(response[i].practiceProfile) {
                            if(vm.practiceProfiles[j].id == response[i].practiceProfile.id) {
                                vm.practiceProfiles[j].practiceName = response[i].name;
                                break;
                            }
                        }
                    }
                }

            }
            function onError(error) {
                console.log("Error in practice-profile.controller.js: ", err);
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
