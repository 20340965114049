(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('PractitionerDetailController', PractitionerDetailController);

    PractitionerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Practitioner', 'Treatment'];

    function PractitionerDetailController($scope, $rootScope, $stateParams, previousState, entity, Practitioner, Treatment) {
        var vm = this;

        vm.practitioner = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('getwellApp:practitionerUpdate', function(event, result) {
            vm.practitioner = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
