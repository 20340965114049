(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('CalendarDialogController', CalendarDialogController);

    CalendarDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Calendar', 'Practice', 'Practitioner'];

    function CalendarDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Calendar, Practice, Practitioner) {
        var vm = this;

        vm.calendar = entity;
        vm.clear = clear;
        vm.save = save;
        // FIXME [dom] change implementation here such that only the id (and not the whole practice or practitioner object) is used 
//        vm.practices = Practice.query();
//        vm.practitioners = Practitioner.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {
            vm.isSaving = true;
            
            if(vm.calendar.practitioner != null && vm.calendar.practitioner.id == null) {
            		vm.calendar.practitioner = null;
            }
            
            if(vm.calendar.practitioner != null) {
            	if((vm.calendar.practitioner.id == null && vm.calendar.type == "RESOURCE") || (vm.calendar.practitioner.id != null && vm.calendar.type == "PERSON")) {
		        	if (vm.calendar.id !== null) {
		                Calendar.update(vm.calendar, onSaveSuccess, onSaveError);
		            } else  {
		                Calendar.save(vm.calendar, onSaveSuccess, onSaveError);
		            } 
		        } else {
		        	onSaveError();
		        	if(vm.calendar.practitioner.id != null && vm.calendar.type == "RESOURCE") {
		        		alert("For the type 'RESOURCE' there should NOT be a practitioner id set..");
		        	} else if(vm.calendar.practitioner.id == null && vm.calendar.type == "PERSON") {
		        		alert("For the type 'PERSON' there should be a practitioner id set..");		        		
		        	} else {
		        		alert("Check input fields please.");
		        	}
		        }
            } else if(vm.calendar.type == "RESOURCE") {
            	if (vm.calendar.id !== null) {
	                Calendar.update(vm.calendar, onSaveSuccess, onSaveError);
	            } else  {
	                Calendar.save(vm.calendar, onSaveSuccess, onSaveError);
	            } 
            } else {
	        	onSaveError();
        		alert("For the type 'PERSON' there should be a practitioner id set..");	
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('getwellApp:calendarUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
