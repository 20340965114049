(function() {
    'use strict';
    angular
        .module('getwellApp')
        .factory('Practice', Practice);

    Practice.$inject = ['$resource'];

    function Practice ($resource) {
        var resourceUrl =  'api/practices/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'getWithCalendars': {
            	method: 'GET',
            	url: 'api/practices/withCalendars/:id',
            	transformResponse: function (data) {
            		if (data) {
            			data = angular.fromJson(data);
            		}
            		return data;
            	}
            },
            'getZawinInfo': {
                method: 'GET',
                url: 'api/practices/:id/zawinInfo',
                transformResponse: function (data) {
                    if (data) {
                        data = { info: data }; // transform string to JavaScript object
                    }
                    return data;
                }
            }
        });
    }
})();
