(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('BookingControllerOld', BookingControllerOld);

    BookingControllerOld.$inject = ['$scope', 'ServiceType', '$stateParams'];

    function BookingControllerOld ($scope, ServiceType, $stateParams) {
        var vm = this;
        
        vm.practice = $stateParams.practice;
        
//        vm.services = [{
//        	name: "Dentalhygiene - 60 min"
//        }, {
//        	name: "Kontrolle - 15 min"
//        }, {
//        	name: "Wurzelbehandlung - 2 h"
//        }];
        
        vm.services = ServiceType.query(function(result) {
        	// TODO [dom] really necessary?
            vm.services = result;
        });
        
        vm.selectedService = null;
        
        vm.images = [{
        	name: "Dr. med. Star",
        	url: "../content/images/Doc1.png"
        }, {
        	name: "Dr. med. Guru",
        	url: "../content/images/Doc2.png"
        }];
        
        vm.showCalendar = showCalendar;
        
        function showCalendar(name) {
        	console.log("showing calendar for: " + name);
        }

    }
})();
