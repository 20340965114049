(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('TreatmentDialogController', TreatmentDialogController);

    TreatmentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Treatment', 'Practice', 'Coupon'];

    function TreatmentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Treatment, Practice, Coupon) {
        var vm = this;

        vm.treatment = entity;
        vm.clear = clear;
        vm.save = save;
        // TODO [dom] change implementation here such that only the id (and not the whole practice object) is used
        //vm.practices = Practice.query();

        //vm.coupons = Coupon.query();
        vm.coupons = Coupon.query({size: 2000});
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            
            if(vm.treatment.displayOnPlatform == false && vm.treatment.displayOnStandalone == false) {
            	alert("AT LEAST one of the two display modes needs to be selected!");
            	onSaveError();
            } else if (vm.treatment.type == "CUSTOM" && (vm.treatment.customName == null || vm.treatment.customName == "")) {
            	alert("The custom name must be set when this treatment type is selected!");
            	onSaveError();
            } else if((vm.treatment.type == "CUSTOM" && vm.treatment.displayOnStandalone == false) || (vm.treatment.type == "CUSTOM" && vm.treatment.displayOnPlatform == true)) {
            	alert("The custom type can only be selected on a STANDALONE only displayed treatment!");
            	onSaveError();
            } else {
                if (vm.treatment.id !== null) {
                    Treatment.update(vm.treatment, onSaveSuccess, onSaveError);
                } else {
                    Treatment.save(vm.treatment, onSaveSuccess, onSaveError);
                }             
            	onSaveError();
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('getwellApp:treatmentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
