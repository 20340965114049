(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('SchedulerController', SchedulerController);

    SchedulerController.$inject = ['$scope', 'Principal', 'Practice', 'Appointment', '$stateParams', '$window'];

    function SchedulerController ($scope, Principal, Practice, Appointment, $stateParams, $window) {
        var vm = this;
        
//        vm.practiceId = $stateParams.practiceId;
        vm.updateCalendar = updateCalendar;
        vm.initPracticeAndCalendars = initPracticeAndCalendars;
        vm.initLightboxSections = initLightboxSections;
        
        Principal.identity().then(function(account) {
        	var practiceId = account.practice;
        	initPracticeAndCalendars(practiceId);
        });
        
//        var scheduler2 = Scheduler.getSchedulerInstance();
//        initScheduler(scheduler2, 'calendar-2');
        
        function initPracticeAndCalendars(practiceId) {
            vm.practice = Practice.getWithCalendars({id: practiceId},
                    function (result) {
        	        	// TODO [dom] really necessary?
        	            vm.practice = result;
        	            
        	            // unfortunately, scheduler state is outside of angularjs view or session lifecycle
        	            if (scheduler.isInitialized !== true) {
        	            	initScheduler(scheduler);
        	            	vm.initLightboxSections();
        	            	scheduler.isInitialized = true;
        	            }
        	            scheduler.init('scheduler-1', new Date(), "week");

        	            reloadSchedulerIfCalendarsChanged();

        	            // TODO [dom] what should happen when there is no calendar in this practice?
        	            vm.selectedCalendar = vm.practice.resourceCalendars[0].id;
        	            vm.updateCalendar();
                });
        }

        function reloadSchedulerIfCalendarsChanged() {
            // TODO [dom] do this also for resource calendars
            var personCalendarIds = vm.practice.personCalendars.map(function(calendar) { return calendar.id; });
            var schedulerCalendarOptions = scheduler.config.lightbox.sections.find(function(section) { return section.name === 'persons'; } ).options;

            if (personCalendarIds.length != schedulerCalendarOptions.length) {
                $window.location.reload();
            } else {
                if (schedulerCalendarOptions.find(function(option) { return !personCalendarIds.includes(option.key); })) {
                    $window.location.reload();
                }
            }
        }

        function getPersonCalendar(id) {
        	return vm.practice.personCalendars.find(function(cal) { return cal.id == id });
        }

        function getResourceCalendar(id) {
        	return vm.practice.resourceCalendars.find(function(cal) { return cal.id == id });
        }
        
        function updateCalendarOptionDefaults() {
        	// TODO [dom] add some more logic, e.g. if only one calendar present, select that automatically, when more are present, select first etc.
        	var personCalendar = getPersonCalendar(vm.selectedCalendar);
        	if (personCalendar !== undefined) {
        		scheduler.defaultPersonCalendar = {id: vm.selectedCalendar};
        		scheduler.defaultResourceCalendar = {id: personCalendar.defaultRelatedCalendarId};
        	} else {
        		var resourceCalendar = getResourceCalendar(vm.selectedCalendar);
        		scheduler.defaultPersonCalendar = {id: resourceCalendar.defaultRelatedCalendarId};
        		scheduler.defaultResourceCalendar = {id: vm.selectedCalendar};
        	}
        }
        
        function updateCalendar() {
        	updateCalendarOptionDefaults();
        	
        	vm.events = Appointment.query({calendarId: vm.selectedCalendar},
            	function(result) {
    	        	// TODO [dom] really necessary?
    	            vm.events = result;

    	            scheduler.clearAll();
    	            scheduler.parse(vm.events, "json");
            });
        }
        
        function initLightboxSections() {
            var personCalendarOptions = vm.practice.personCalendars.map(function(calendar) {
            	return {key: calendar.id, label: calendar.name};
            });
            var resourceCalendarOptions = vm.practice.resourceCalendars.map(function(calendar) {
            	return {key: calendar.id, label: calendar.name};
            });
        	
        	// TODO [dom] insert type in label files instead, and insert labels for options in label files
            scheduler.locale.labels.drag_to_create = "Ziehen zum Erstellen";
            scheduler.locale.labels.drag_to_move = "Bewegen zum Verschieben";
            scheduler.locale.labels.message_cancel = "Abbrechen";
        	scheduler.locale.labels.section_type = "Typ";
        	scheduler.locale.labels.section_persons = "Personen";
        	scheduler.locale.labels.section_resources = "Ressourcen";
        	scheduler.locale.labels.section_repeat = "Wiederholung";
        	scheduler.locale.labels.section_repetition = "Anzahl Wieder.";
       		scheduler.config.lightbox.sections=[
    		    {name:"description", height:200, map_to:"text", type:"textarea" , focus:true},
    		    {name:"type", height:46, map_to:"type", type:"radio", options: [{key:"BOOKABLE", label:"buchbar"}, {key:"OCCUPIED", label:"belegt"}], vertical: true, default_value: "OCCUPIED"},
    		    {name:"persons", height:26*personCalendarOptions.length, map_to:"personCalendarIds", type:"multiselect", options: personCalendarOptions, vertical: true},
    		    {name:"resources", height:26*resourceCalendarOptions.length, map_to:"resourceCalendarIds", type:"multiselect", options: resourceCalendarOptions, vertical: true},
    		    {name:"time", height:72, type:"time", map_to:"auto"},
    		    {name:"repeat", height:26, map_to:"repeat", type:"select", onchange: updateRepetitionState, options: [{key:"ONCE", label:"Einmalig"}, {key:"WORKDAYS", label:"Arbeitstäglich"}, {key:"WEEKS", label:"Wöchentlich"}] },
    		    {name:"repetition", height:10, map_to:"repetition", type:"textarea"}
    		]
        }

        function updateRepetitionState() {
            var repetitionElement = $(".dhx_cal_ltext").eq(3); // the repetition element currently is the fourth element with the class 'dhx_cal_ltext'
            var disabled = $("option[value=ONCE]").parent().val() == 'ONCE'; // if the value 'ONCE' is selected, we want to disable the repetition field
            var targetHeight = disabled ? "10px" : "28px";

            repetitionElement.css('height', targetHeight);
            if (disabled) {
                repetitionElement.children('textarea').val('');
            }
        }
        
        function configureSchedulerFullweekTab(schedulerInstance) {
            schedulerInstance.date.fullweek_start = function(date) {
                return schedulerInstance.date.week_start(date);
            };
            schedulerInstance.date.get_fullweek_end = function(start_date) {
                return schedulerInstance.date.add(start_date,7,"day"); 
            };
            schedulerInstance.date.add_fullweek = function(date,inc) { 
                return schedulerInstance.date.add(date,inc*7,"day");
            };
            schedulerInstance.templates.fullweek_date = schedulerInstance.templates.week_date;
            schedulerInstance.templates.fullweek_scale_date = schedulerInstance.templates.week_scale_date;
        }
        
        function initScheduler(schedulerInstance) {
        	schedulerInstance.skin = 'flat'
//        	schedulerInstance.config.xml_date="%Y-%m-%dT%H:%i:%sZ";
        	schedulerInstance.config.server_utc = true;
        	schedulerInstance.config.day_date = "%D, %j.%n.";
        	schedulerInstance.config.scroll_hour = 7;
        	schedulerInstance.config.details_on_dblclick = true;
        	schedulerInstance.config.details_on_create = true;
        	schedulerInstance.config.separate_short_events = true;
        	schedulerInstance.config.time_step = 15;
        	schedulerInstance.config.hour_size_px = 64;
        	schedulerInstance.xy.min_event_height = 16;


        	schedulerInstance.templates.event_class = function(start, end, event) {
				var css = "event_" + event.type;

				if (event.id == scheduler.getState().select_id) {
					css += " selected";
				}
				return css;
			};

			schedulerInstance.renderEvent = function(container, ev, w, h, contentA, contentB) {
				// the following code is heavily based on the function scheduler._render_v_bar from the source of dhtmlxscheduler.js version v.4.4.0. Beware, that on new versions, the code might have to be updated!
				var bg_color = (ev.color ? ("background:" + ev.color + ";") : "");
				var color = (ev.textColor ? ("color:" + ev.textColor + ";") : "");
				
				var inner_html = '<div class="dhx_event_move dhx_header" style=" width:' + (w - 6) + 'px;' + bg_color + '" >&nbsp;</div>';

				var body_classes_small = 'dhx_event_move dhx_body_small ';
				var body_height = 14;
				var event_duration_in_min = (ev.end_date - ev.start_date) / 60000;
				if ( event_duration_in_min > 30) {
					inner_html += '<div class="dhx_event_move dhx_title" style="' + bg_color + '' + color + '">' + contentA + '</div>';
					body_classes_small = '';
					body_height = (h - (this._quirks ? 20 : 30) + 1);
				} else {
					if ( event_duration_in_min > 15) {
						body_height = 30;
					}
				}
				inner_html += '<div class="' + body_classes_small + 'dhx_body" style=" width:' + (w - (this._quirks ? 4 : 14)) + 'px; height:' + body_height + 'px;' + bg_color + '' + color + '">' + contentB + '</div>';

				var footer_class = "dhx_event_resize dhx_footer";
				inner_html += '<div class="' + footer_class + '" style=" width:' + (w - 8) + 'px;' + bg_color + '' + color + '" ></div>';
	
				container.innerHTML = inner_html;
				
				return true;
			};
			
			// 0 refers to Sunday, 6 to Saturday
			schedulerInstance.ignore_week = function(date) {
			    if (date.getDay() == 6 || date.getDay() == 0) { // hides Saturdays and Sundays
			        return true;
			    }
			};
			
        	initResponsive(schedulerInstance);
        	
        	schedulerInstance.locale.labels.week_tab = "5 Tage";
        	schedulerInstance.locale.labels.fullweek_tab = "Woche";
        	schedulerInstance.attachEvent("onTemplatesReady", function() {
        	    configureSchedulerFullweekTab(schedulerInstance);
        	});
        	
        	// set calendar option defaults
        	schedulerInstance.attachEvent("onEventCreated", function(id) {
    	         var event = schedulerInstance.getEvent(id);
    	         event.personCalendarIds = schedulerInstance.defaultPersonCalendar !== undefined ? schedulerInstance.defaultPersonCalendar['id'] : undefined;
    	         event.resourceCalendarIds = schedulerInstance.defaultResourceCalendar !== undefined ? schedulerInstance.defaultResourceCalendar['id'] : undefined;
        	});

        	schedulerInstance.attachEvent("onLightbox", function(id) {
        	    updateRepetitionState();
        	});

        	schedulerInstance.attachEvent("onEventChanged", function(id, event) {
        		console.log("Changing: id:" + id + " event:" + event);
        		handleRepetition(event);
                Appointment.update(event, function (result) {
                	// TODO [dom] event may not belong to current calendar anymore. refresh events?
                	console.log("Success!")
                	// TODO [dom] find way to set all fields back from persisted entity
                	event.lockingVersion = result.lockingVersion;
                	schedulerInstance.changeEventId(id, result.id);
                }, onSaveError);
        	});

        	schedulerInstance.attachEvent("onEventAdded", function(id, event) {
        		console.log("Adding: id:" + id + " event:" + event);
        		handleRepetition(event);
        		Appointment.save(event, function (result) {
        			// TODO [dom] event may not belong to current calendar. refresh events?
                	console.log("Success!")
                	console.log("Event id before:" + event.id);
                	// TODO [dom] find way to set all fields back from persisted entity
                	event.lockingVersion = result.lockingVersion;
                	schedulerInstance.changeEventId(id, result.id);
                	console.log("Event id after:" + event.id);
                }, onSaveError);
        	});
        	
        	schedulerInstance.attachEvent("onConfirmedBeforeEventDelete", function(id, event) {
        		if(event.lockingVersion != undefined) { // only try to delete if event already exists in DB
	        		console.log("Deleting: id:" + id + " event:" + event);
	        		
	                Appointment.delete({id: id}, onSaveSuccess, onSaveError);
	        	    // TODO [dom] check if there needs to be some additional error-handling done
        		}
        		return true;
        	});

        	function handleRepetition(event) {
        	    var resultingEventIds = [];
        	    var repetitionNumber = Number(event.repetition);
        	    repetitionNumber = Math.min(repetitionNumber, 5); // we only want to support a maximum repetition of 5

        	    if ((event.repeat == 'WORKDAYS' || event.repeat == 'WEEKS') && repetitionNumber > 0) {
        	        var isRepeatDays = event.repeat == 'WORKDAYS';
        	        var isDefaultText = event.text == 'neuer Eintrag';
        	        event.repeat = 'ONCE';
        	        event.repetition = '';
        	        var currentStartTime = moment(event.start_date);
        	        var currentEndTime = moment(event.end_date);

        	        for (var i=0;i<repetitionNumber;i++) {
        	            currentStartTime = isRepeatDays ? addWeekdays(currentStartTime, 1) : currentStartTime.clone().add(1, 'w');
        	            currentEndTime = isRepeatDays ? addWeekdays(currentEndTime, 1) : currentEndTime.clone().add(1, 'w');

        	            var newEventId = schedulerInstance.addEvent({
        	                    text: isDefaultText ? event.text : event.text + ' ' + (i+2),
        	                    type: event.type,
        	                    personCalendarIds: event.personCalendarIds.slice(),
        	                    resourceCalendarIds: event.resourceCalendarIds.slice(),
        	                    start_date: currentStartTime.toDate(),
        	                    end_date: currentEndTime.toDate(),
        	                    repeat: 'ONCE',
        	                    repetition: ''
        	                });

        	            resultingEventIds.push(newEventId);
        	        }
        	    }
        	    return resultingEventIds;
        	}

        	function addWeekdays(date, days) {
        	    date = date.clone();
        	    while (days > 0) {
        	        date = date.add(1, 'days');
        	        if ((date.isoWeekday() !== 6) && (date.isoWeekday() !== 7)) {
        	            days -= 1;
        	        }
        	    }
        	    return date;
        	}
        	
            function onSaveSuccess (result) {
            	console.log("Success!");
            }

            function onSaveError (httpResponse) {
            	// TODO [dom] show error message to user incl. translations, e.g. for 'error.concurrencyFailure'
            	console.log("Error: " + JSON.stringify(httpResponse));
            }
        }
    }
})();
