(function() {
    'use strict';

    angular
        .module('getwellApp')
        .controller('PracticeDetailController', PracticeDetailController);

    PracticeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Practice', 'Calendar', 'Practitioner', 'PracticeProfile', '$uibModal'];

    function PracticeDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Practice, Calendar, Practitioner, PracticeProfile, $uibModal) {
        var vm = this;

        vm.practice = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.showZawinInfo = showZawinInfo;
        vm.isZawinCalendar = isZawinCalendar;
        loadZawinInfo();

        function loadZawinInfo() {
            if (isZawinCalendar()) {
                vm.zawinInfo = Practice.getZawinInfo({id: vm.practice.id});
            }
        }

        function showZawinInfo (zawinInfo) {
            $uibModal.open({
                template: '<div class="modal-body pad"><span style="white-space: pre-wrap;">{{vm.zawinInfo}}</span></div>',
                controller: function() { var vm = this; vm.zawinInfo = zawinInfo; },
                controllerAs: 'vm',
                size: 'lg'
            });
        }

        function isZawinCalendar() {
            return vm.practice.calendarType == 'ZAWIN';
        }

        var unsubscribe = $rootScope.$on('getwellApp:practiceUpdate', function(event, result) {
            vm.practice = result;
            loadZawinInfo();
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
