(function() {
    'use strict';

    angular
        .module('getwellApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('practice', {
            parent: 'entity',
            url: '/practice?page&sort&search&teaser',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'getwellApp.practice.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/practice/practices.html',
                    controller: 'PracticeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null,
                teaser: 'false'
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        teaser: $stateParams.teaser
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('practice');
                    $translatePartialLoader.addPart('professionalCategory');
                    $translatePartialLoader.addPart('practiceLocation');
                    $translatePartialLoader.addPart('practiceCalendarType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('practice-detail', {
            parent: 'practice',
            url: '/practice/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'getwellApp.practice.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/practice/practice-detail.html',
                    controller: 'PracticeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('practice');
                    $translatePartialLoader.addPart('professionalCategory');
                    $translatePartialLoader.addPart('practiceLocation');
                    $translatePartialLoader.addPart('practiceCalendarType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Practice', function($stateParams, Practice) {
                    return Practice.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'practice',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('practice-detail.edit', {
            parent: 'practice-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/practice/practice-dialog.html',
                    controller: 'PracticeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Practice', function(Practice) {
                            return Practice.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('practice.new', {
            parent: 'practice',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/practice/practice-dialog.html',
                    controller: 'PracticeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                phone: null,
                                fax: null,
                                addressStreet: null,
                                addressStreetNumber: null,
                                addressCity: null,
                                addressStreetMore: null,
                                addressPostalCode: null,
                                email: null,
                                professionalCategory: null,
                                networkedPracticeName: null,
                                networkedPractice: null,
                                picture: null,
                                pictureContentType: null,
                                notes: null,
                                taxpunktwert: null,
                                bookingTerms: null,
                                location: null,
                                coordinates: null,
                                showBusinessCard: false,
                                locationDescription: null,
                                profilePath: null,
                                externalCalendarId: null,
                                externalCalendarCredential: null,
                                externalCalendarData: null,
                                languages: null,
                                specializations: null,
                                teaser: null,
                                teaserData: null,
                                standaloneStyle: null,
                                calendarType: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('practice', null, { reload: 'practice' });
                }, function() {
                    $state.go('practice');
                });
            }]
        })
        .state('practice.edit', {
            parent: 'practice',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/practice/practice-dialog.html',
                    controller: 'PracticeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Practice', function(Practice) {
                            return Practice.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('practice', null, { reload: 'practice' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('practice.delete', {
            parent: 'practice',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/practice/practice-delete-dialog.html',
                    controller: 'PracticeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Practice', function(Practice) {
                            return Practice.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('practice', null, { reload: 'practice' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
