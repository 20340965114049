(function() {
    'use strict';
    angular
        .module('getwellApp')
        .factory('Appointment', Appointment);

    Appointment.$inject = ['$resource', 'DateUtils'];

    function Appointment ($resource, DateUtils) {
        var resourceUrl =  'api/appointments/:id';

        return $resource(resourceUrl, {}, {
            'query': {
            	method: 'GET',
            	isArray: true,
            	url: 'api/appointments/calendar/:calendarId',
            	transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        // convert string to date object
                        data.forEach(function(entry) {
                        	entry.start_date = DateUtils.convertDateTimeFromServer(entry.start_date);
                        	entry.end_date = DateUtils.convertDateTimeFromServer(entry.end_date);
                        	
                        });
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
//                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.start_date = DateUtils.convertDateTimeFromServer(data.start_date);
                        data.end_date = DateUtils.convertDateTimeFromServer(data.end_date);
//                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                    }
                    return data;
                }
            },
            'update': {
            	method:'PUT',
            	transformRequest: function (data) {
        			// sometimes, scheduler converts Arrays to Strings and we must convert them back
        			if (typeof data.personCalendarIds === "string") {
        				data.personCalendarIds = data.personCalendarIds.split(",");
        			}
        			if (typeof data.resourceCalendarIds === "string") {
        				data.resourceCalendarIds = data.resourceCalendarIds.split(",");
        			}
        			data = angular.toJson(data);
        			return data;
            	}
            },
            'save': {
            	method:'POST',
        		transformRequest: function (data) {
        			// sometimes, scheduler converts Arrays to Strings and we must convert them back
        			if (typeof data.personCalendarIds === "string") {
        				data.personCalendarIds = data.personCalendarIds.split(",");
        			}
        			if (typeof data.resourceCalendarIds === "string") {
        				data.resourceCalendarIds = data.resourceCalendarIds.split(",");
        			}
        			data = angular.toJson(data);
        			return data;
        		}
            }
        });
    }
})();
